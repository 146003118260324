<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Condition Monitoring'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <vue-suggestion
                    id="equipment_id"
                    :items="equipment"
                    v-model="ItemEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeEquipment"
                    @selected="chooseEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                    required
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_condition_monitoring_label"
                  label="Nama Condition Monitoring :"
                  label-for="nama_condition_monitoring"
                >
                  <b-form-input
                    id="nama_condition_monitoring"
                    v-model="$v.form.nama_condition_monitoring.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_condition_monitoring')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_condition_monitoring_feedback">
                    Nama Condition Monitoring Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/master/conditionmonitoring')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'addgrouppoint',
  data() {
    return {
      form: {
        nama_condition_monitoring: null,
        equipment_id: null,
      },
      equipment: [],
      ItemEquipment: null,
      itemTemplate,

      loading: false,
    }
  },
  validations: {
    form: {
      nama_condition_monitoring: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Master', route: '/master/conditionmonitoring' },
      { title: 'Create Condition Monitoring' },
    ])
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      self.loading = true
      const nama_condition_monitoring = self.$v.form.nama_condition_monitoring.$model
      const equipment_id = self.form.equipment_id

      ApiService.post('/master/condition_monitoring/store', {
        nama_condition_monitoring: nama_condition_monitoring,
        equipment_id: equipment_id,
      })
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/master/conditionmonitoring')
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text
      if (this.form.pabrik_id && text) {
        url += '/' + this.form.pabrik_id
      }

      if (this.form.plant_id && text) {
        url += '/' + this.form.plant_id
      }

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
